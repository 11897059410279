<template>
  <div class="search-bar search-bar--negative-section-margin theme-dark">
    <div class="search-bar__inner">
      <div class="search-bar__description">
        {{ $textDictionary['downloadCenterSoftware.searchDescription'] ?? '' }}
      </div>
      <div class="search-bar__input">
        <FormKit
          :id="searchFormId"
          type="form"
          name="search-form"
          :actions="false"
          @submit="
            () => {
              store.fetchSoftwareDownloads();

              $analytics?.pushToDataLayer({
                action: 'software_search',
                category: 'software',
                event: 'search-software',
                label: store.query
              });
            }
          "
        >
          <FormKit
            id="search-box-value"
            v-model="store.query"
            type="search"
            name="search-value"
            outer-class="fk-search-bar"
            :floating-label="false"
            autocomplete="off"
            :placeholder="
              $textDictionary['softwareCenter.searchPlaceholder'] ?? ''
            "
            :label="$textDictionary['softwareCenter.searchPlaceholder'] ?? ''"
          >
            <template #suffix>
              <button type="submit" class="fk-search-bar__submit" tabindex="-1">
                <FormKitIcon icon="search" />
              </button>
            </template>
          </FormKit>
        </FormKit>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FormKitIcon } from '@formkit/vue';
import { useDownloadStore } from '~/stores/downloadStore/softwareDownloadStore';

const { $textDictionary, $analytics } = useNuxtApp();
const searchFormId = useId();

const store = useDownloadStore();
</script>

<style module></style>
